import React from 'react';
import Helmet from 'react-helmet';
import * as styles from './Layout.module.scss';

class Layout extends React.Component {
  render() {
    return (
      <div className={styles.layout}>
        <Helmet>
          <html lang="en" />
          <title>{this.props.title}</title>
          <meta name="description" content={this.props.description} />
          <meta itemProp="name" content={this.props.title} />
          <meta itemProp="description" content={this.props.description} />
          <meta name="og:title" content={this.props.title} />
          <meta name="og:description" content={this.props.description} />
          <meta name="og:site_name" content="Blog by Amr Yousef" />
          <meta name="og:locale" content="en_GB" />
          <meta name="og:type" content="article" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content={this.props.title}/>
          <meta name="twitter:description" content={this.props.description} />
          {this.props.LdSchema &&
            <script type="application/ld+json">{this.props.LdSchema}</script>
          }
          {this.props.canonicalUrl && <link rel="canonical" href={this.props.canonicalUrl} /> }
          { this.props.canonicalUrl && <link rel="canonical" href={this.props.canonicalUrl} /> }
          {this.props.openGraphImage && <meta name="twitter:image" content={this.props.openGraphImage}/> }
          {this.props.openGraphImage && <meta name="image" property="og:image" content={this.props.openGraphImage} /> }
          {this.props.openGraphImage && <meta name="og:image" content={this.props.openGraphImage} /> }
          {this.props.openGraphImage && <meta name="twitter:image:src" content={this.props.openGraphImage} /> }
        </Helmet>
        {this.props.children}
      </div>
    );
  }
}

export default Layout;
